import classNames from 'classnames';
import { Button, Markdown } from '@engbers/components';
import { useAccount } from '@frontastic-engbers/lib';
import Image from '@frontastic-engbers/lib/lib/image';
import styles from './image-text.module.scss';
export const ImageText = ({
  imageUrl,
  text,
  padding,
  alignment,
  textColor,
  backgroundColor,
  width,
  icon,
  margin = 0,
  iconWidth,
  buttonLabel,
  buttonLink,
  buttonCustomIconURL,
  buttonCustomIconWidth = '30',
  buttonType,
  buttonColor,
  buttonTextColor,
  boxAlignment = 'bottom-left',
  fullHeight,
  verticalAlignment,
  buttonWidth
}) => {
  const {
    account
  } = useAccount();
  let textWithReplacements = '';
  if (account) {
    textWithReplacements = text.replace('{firstName}', account.firstName).replace('{lastName}', account.lastName).replace('{email}', account.email).replace('{birthday}', account.birthday?.toLocaleDateString('de-DE')).replace('{address.city}', account.addresses?.[0]?.city).replace('{address.phone}', account.addresses?.[0]?.phone).replace('{address.postalCode}', account.addresses?.[0]?.postalCode).replace('{address.streetName}', account.addresses?.[0]?.streetName).replace('{address.streetNumber}', account.addresses?.[0]?.streetNumber).replace('{cardId}', account.cardId);
  }
  return <div className={styles.wrap} data-sentry-component="ImageText" data-sentry-source-file="index.tsx">
      {imageUrl && <div className={styles.imageWrap}>
          <Image alt={text} src={imageUrl} />
        </div>}

      <div className={classNames(styles.text, {
      [styles[`alignment--${alignment}`]]: !!alignment,
      [`p-${padding}`]: !!padding,
      [`m-${margin}`]: !!margin,
      [styles[`vertical-alignment--${verticalAlignment}`]]: !!verticalAlignment,
      [styles[`box-alignment--${boxAlignment}`]]: !!boxAlignment
    })} style={{
      color: textColor,
      backgroundColor: backgroundColor,
      width: `${width}%`,
      height: fullHeight ? `calc(100% - ${margin * 2 * 4}px)` : undefined
    }}>
        {icon ? <div className={styles.iconWrap} style={{
        width: `${iconWidth}px`
      }}>
            <Image alt={text} src={icon} />
          </div> : null}
        <Markdown text={textWithReplacements} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
        {buttonLabel ? <div className={styles.buttonRow} style={{
        width: `${buttonWidth}%`
      }}>
            <Button link={buttonLink} label={buttonLabel} customIconURL={buttonCustomIconURL} customIconWidth={buttonCustomIconWidth} type={buttonType} color={buttonColor} textColor={buttonTextColor} />
          </div> : null}
      </div>
    </div>;
};