import { useRecoilValueLoadable } from 'recoil';
import { NavigationSide } from '@engbers/components/navigation-side';
import { unreadAllCount } from 'state/messages/atoms';
const EngbersContentNavigationTastic = ({
  data
}) => {
  const unreadAllCountNow = useRecoilValueLoadable(unreadAllCount);
  return <NavigationSide headline={data.headline} headlineTextColor={data.headlineTextColor} headlineBgColor={data.headlineBgColor} headlinePosition={data.headlinePosition} bannerPicture={data.bannerImage} items={data.navigationItems.map(item => {
    return {
      label: item.label,
      id: item.label,
      reference: item.reference,
      icon: {
        width: item[`icon-width`],
        url: item.icon?.media.file
      },
      counter: item.hasMessageCounter && unreadAllCountNow?.state === 'hasValue' ? unreadAllCountNow?.contents : undefined,
      isLogoutButton: item.isLogoutButton
    };
  })} topIcon={{
    url: data.icon?.media.file,
    width: 28
  }} data-sentry-element="NavigationSide" data-sentry-component="EngbersContentNavigationTastic" data-sentry-source-file="index.tsx" />;
};
export default EngbersContentNavigationTastic;