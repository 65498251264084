import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// Logic for push notification badges
// In the service worker there is logic that updates the badge whenever a push notification arrives
// It basically just adds 1 to the current count
// 
// In the app there is own logic to make sure the count is correct
// Also, when push notifications are not allowed by the user, this corrects the count in the external app badge
// - Logic for messages and news is exactly the same
// - The badge count is the unread messages + unread news
// - All messages (and read list) are persisted in local storage.
// - Each message that is read, will be added to an own list "readMessages"
// - Always when opening the messages page in the app, all current messages are moved to "readMessages"
// - The real badge count can be figured out by checking which messages are already in the readMessages list
interface IMessages {
  allMessages: any[];
  readMessages: string[];
  allNews: any[];
  allRealNews: any[];
  readNews: string[];
}

export const messagesState = atom<IMessages>({
  key: 'messagesState',
  default: {
    allMessages: [],
    readMessages: [],
    allNews: [],
    allRealNews: [],
    readNews: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const unreadAllCount = selector<number>({
  key: 'unreadAllCount',
  get: ({ get }) => {
    const messages = get(messagesState);
    return (
      messages.allMessages.filter((mess) => !messages.readMessages.includes(mess.itemId)).length +
      messages.allNews.filter((mess) => !messages.readNews.includes(mess.itemId)).length
    );
  },
});

export const unreadMessageCount = selector<number>({
  key: 'unreadMessageCount',
  get: ({ get }) => {
    const messages = get(messagesState);
    return messages.allMessages.filter((mess) => !messages.readMessages.includes(mess.itemId)).length;
  },
});

export const unreadNewsCount = selector<number>({
  key: 'unreadNewsCount',
  get: ({ get }) => {
    const messages = get(messagesState);
    return messages.allNews.filter((mess) => !messages.readNews.includes(mess.itemId)).length;
  },
});
