import React from 'react';
import { TIconId } from '@engbers/components/icon-custom';
import { NavigationBottomItem } from './components/navigation-bottom-item';
import styles from './navigation-bottom.module.scss';
interface INavigationItem {
  id: string;
  label: string;
  icon: TIconId;
  counter?: number;
  reference: any;
}
export interface INavigationBottom {
  items?: INavigationItem[];
  activeId?: string;
}
export const NavigationBottom: React.FC<INavigationBottom> = ({
  items,
  activeId = 'home'
}) => {
  return <nav className={styles.navigationWrap} data-sentry-component="NavigationBottom" data-sentry-source-file="index.tsx">
      <ul className={styles.navigation}>
        {items.map(item => {
        return <NavigationBottomItem reference={item.reference} counter={item.counter} isActive={activeId === item.id} key={`key-${item.id}`} {...item} />;
      })}
      </ul>
    </nav>;
};