import { useAccount } from '@frontastic-engbers/lib/provider';
import { HeaderGreeting } from '../../../../components/engbers-specific/header-greeting';
const EngbersGreetingBannerTastic = ({
  data
}) => {
  const {
    account
  } = useAccount();
  return <div data-sentry-component="EngbersGreetingBannerTastic" data-sentry-source-file="index.tsx">
      <HeaderGreeting message={data.text} name={account?.firstName || ''} textAlign={data.textAlign} background={data.background} textColor={data.textColor} data-sentry-element="HeaderGreeting" data-sentry-source-file="index.tsx" />
    </div>;
};
export default EngbersGreetingBannerTastic;