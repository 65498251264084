import React from 'react';
import classNames from 'classnames';
import styles from './teaser.module.scss';
import { IFTLinkReference, IFTPageFolderReference } from '@frontastic-engbers/types/engbers-custom';
import { Button } from '@engbers/components';
interface ITeaser {
  headline: string;
  image: {
    url: string;
    objectFit?: 'cover';
  };
  icon?: {
    url: string;
    width: string | number;
  };
  hasCenterDivider?: boolean;
  isContentLeft?: boolean;
  // as Hex color
  backgroundColor?: string;
  textColor?: string;
  buttonOptions?: {
    textColor?: string;
    backgroundColor?: string;
    link?: IFTPageFolderReference | IFTLinkReference;
    label: string;
    icon?: string;
    type?: 'cta' | 'primary' | 'secondary';
    iconWidth?: string;
  };
}
export const Teaser: React.FC<ITeaser> = ({
  buttonOptions,
  headline,
  image,
  icon,
  hasCenterDivider,
  isContentLeft,
  backgroundColor
}) => {
  return <div className={classNames(styles.teaserWrap, {
    [styles['teaserWrap--hasCenterDivider']]: hasCenterDivider,
    [styles['teaserWrap--isContentLeft']]: isContentLeft
  })} style={{
    background: backgroundColor || undefined
  }} data-sentry-component="Teaser" data-sentry-source-file="index.tsx">
      <div className={classNames(styles.col, styles.imageWrap)}>
        <img className={classNames(styles.image, {
        [styles[`image--objectFit-${image.objectFit}`]]: image.objectFit
      })} src={image.url} />
      </div>
      <div className={classNames(styles.col, styles.contentWrap)}>
        {icon && <div className={styles.iconWrap}>
            <img width={icon.width} src={icon.url} />
          </div>}
        <div className={styles.headline}>{headline}</div>
        {buttonOptions?.label && <div className={styles.linkWrap}>
            <Button customIconURL={buttonOptions?.icon || undefined} label={buttonOptions?.label || undefined} customIconWidth={buttonOptions.iconWidth || undefined} type={buttonOptions.type || undefined} color={buttonOptions.backgroundColor || undefined} textColor={buttonOptions.textColor || undefined} link={buttonOptions.link || undefined} />
          </div>}
      </div>
    </div>;
};