import { SectionLayout } from '@engbers/components';
import { Teaser } from '../../../../components/engbers-specific/teaser';
const EngbersTeaserType1Tastic = ({
  data
}) => {
  return <SectionLayout data-sentry-element="SectionLayout" data-sentry-component="EngbersTeaserType1Tastic" data-sentry-source-file="index.tsx">
      <Teaser headline={data.headline} hasCenterDivider={data.hasDivider} isContentLeft={data.isImageRight} icon={{
      url: data.icon?.media.file,
      width: data.icon_width || 24
    }} image={{
      url: data.image?.media?.file,
      objectFit: data.isObjectFitCover ? 'cover' : undefined
    }} buttonOptions={{
      label: data.label,
      link: data.link,
      textColor: data.buttonTextColor,
      backgroundColor: data.buttonBackgroundColor,
      icon: data.buttonIcon?.media?.file,
      iconWidth: data.buttonIconWidth,
      type: data.buttonType
    }} backgroundColor={data.backgroundColor} data-sentry-element="Teaser" data-sentry-source-file="index.tsx" />
    </SectionLayout>;
};
export default EngbersTeaserType1Tastic;