import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import { HeaderMain } from '@engbers/components/header-main';
import { HeaderMinimized } from '@engbers/components/header-minimized';
import { useCurrentShop } from '@frontastic-engbers/lib';
import LoadingPlaceholder from '@frontastic-engbers/lib/lib/loading-placeholder';
export const HeaderTastic = ({
  data
}) => {
  let isCheckout = false;
  const currentSection = useCurrentShop();
  const isEnGermany = currentSection === 'engbers-germany';
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1024
  });
  const HeaderServiceArea = dynamic(() => import('@engbers/components/header-service-area').then(module => module.HeaderServiceArea), {
    loading: () => <LoadingPlaceholder height="40px" />
  });
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    isCheckout = window.location.pathname.includes('checkout') || window.location.href.includes('checkout');
  }, []);
  return <>
      {isCheckout || isMinWidthMedium ? <HeaderMinimized mobileCheckoutLogo={data.mobileCheckoutLogo} mobileCheckoutLogoLink={data.mobileCheckoutLogoLink} trustedShopIcon={data.trustedShopIcon} trustedShopText={data.trustedShopText} isCheckout={isCheckout} isEnGermany={isEnGermany} /> : <>
          <HeaderServiceArea services={data.services} />
          <HeaderMain logos={data.logos} items={data.items} />
        </>}
    </>;
};