import React from 'react';
import classNames from 'classnames';
import styles from './header-greeting.module.scss';
interface IHeaderGreeting {
  message: string;
  name: string;
  background?: string;
  textColor?: string;
  textAlign?: 'left' | 'right' | 'center';
}
export const HeaderGreeting: React.FC<IHeaderGreeting> = ({
  message,
  name,
  background,
  textColor,
  textAlign
}) => {
  const messageWithName = message.replace('{name}', name);
  return <div data-sentry-component="HeaderGreeting" data-sentry-source-file="index.tsx">
      <div className={classNames(styles.wrap, {
      [styles[`alignment--${textAlign}`]]: !!textAlign
    })} style={{
      color: textColor || undefined,
      background: background || undefined
    }}>
        <span>{messageWithName}</span>
      </div>
    </div>;
};